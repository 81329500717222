export default Intro;

function Intro(props) {
 
  

  return (
    <div className="introBody">
      {props.loading && ""}
      <center>
        <h1>من فريق العمل:</h1>
      </center>

      {!props.loading && (
        <div>
          {props.data
            .filter(function (i) {
              return i.Najla !== undefined;
            })
            .map((e) => (
              <p
                key={"Najla " + e.Id}
                style={{ textIndent: e.Id === "1" && "3vh" }}
              >
                {e.Najla}
              </p>
            ))}

          <p className="sign">
            نجلاء علي البهلولي <br />
            <br />
          </p>

          {props.data
            .filter(function (i) {
              return i.Arfat !== undefined;
            })
            .map((e) => (
              <p
                key={"Arfat " + e.Id}
                style={{ textIndent: e.Id === "1" && "3vh" }}
              >
                {e.Arfat}
              </p>
            ))}

          <p className="sign">
            عرفات علي البهلولي <br />
            <br />
          </p>
          
          {props.data[0].AlMuhsinAlabbas !== undefined && (

            <div>

          {props.data
            .filter(function (i) {
              return i.AlMuhsinAlabbas !== undefined;
            })
            .map((e) => (
              <p
                key={"AlMuhsinAlabbas " + e.Id}
                style={{ textIndent: e.Id === "1" && "3vh" }}
              >
                {e.AlMuhsinAlabbas}
              </p>
            ))}

          <p className="sign">
          المحسن بن العباس حميدالدين <br />
            <br />
          </p>

          </div>

          )}


 
        </div>
      )}

  

    </div>
  );
}
