/* import { IoInformationCircle } from "react-icons/io5"; */

import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Navbar,
  Nav,
  /* NavDropdown ,*/ Container,
  Button,
  ThemeProvider,
  Form,
  Row,
  Col,
  Accordion,
  OverlayTrigger,
  Tooltip,
  /*   Overlay, */
} from "react-bootstrap";

import { Worker } from "@react-pdf-viewer/core";

// Import the main component
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { scrollModePlugin } from "@react-pdf-viewer/scroll-mode";
import { ScrollMode } from "@react-pdf-viewer/scroll-mode";
import { selectionModePlugin } from "@react-pdf-viewer/selection-mode";
import { searchPlugin } from "@react-pdf-viewer/search";

import { bookmarkPlugin } from "@react-pdf-viewer/bookmark";

// Import styles
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/scroll-mode/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "@react-pdf-viewer/selection-mode/lib/styles/index.css";
import "@react-pdf-viewer/bookmark/lib/styles/index.css";

import Intro from "./Intro";

//var myHeaders = new Headers();

/* var sheetdata = require("./data.js"); */

var gSheet = [];

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var getData = () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        "https://0fhdyyj31k.execute-api.us-east-2.amazonaws.com/default/aleimansheet",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          gSheet = JSON.parse(result);

          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    };

    getData();
  }, [loading]);

  const testStyle = {
    visibility: "hidden",
    display: "none",
  };

  return (
    <div className="main">
      <ThemeProvider dir="rtl">
        <div style={testStyle}>test</div>

        <BrowserRouter>
          <Navbar
            dir="rtl"
            className="navbar-yemen me-auto"
            variant="dark"
            expand="sm"
            sticky="top"
          >
            <Container>
              <Nav.Link className="yemen-navlink" as={Link} to="/browse">
                التصفح
              </Nav.Link>

              <Nav className="navbar-brand mx-auto">
                <Container>
                  <Row>
                    <Col>
                      <Navbar.Brand
                        className="yemen-navlink yemen-nav-brand"
                        as={Link}
                        to="/"
                        style={{ marginLeft: 0 }}
                      >
                        <span>جريدة الإيمان </span>
                      </Navbar.Brand>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        className="blinking"
                        style={{
                          color: "black",
                          fontSize: "2vh",
                          textAlign: "center",
                        }}
                      >
                        تشغيل تجريبي
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Nav>
              <Nav.Link className="yemen-navlink" as={Link} to="/contact">
                التواصل
              </Nav.Link>
            </Container>
          </Navbar>
          <Switch>
            {/* If the current URL is /about, this route is rendered
            while the rest are ignored */}
            <Route path="/about">
              <About />
            </Route>

            <Route path="/browse">
              <Browse loading={loading} />
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>

            <Route path="/issue/:id">
              <Issue loading={loading} />
            </Route>

            <Route exact path="/">
              <Home loading={loading} />
            </Route>

            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;

function About() {
  return (
    <div>
      <h1>About</h1>
      <Link to="/">Home</Link>
    </div>
  );
}

function Browse(props) {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(true);

  document.title = "جريدة الإيمان - التصفح"


  useEffect(() => {
    setLoading(props.loading);

    if (!loading) {
      setData(gSheet[1].data);
      setData2(gSheet[0].data);
    }
  }, [data, loading, props]);

  return (
    /* https://react-bootstrap.netlify.app/components/accordion/#fully-collapsed-state */

    <div dir="rtl">
      {loading && ""}

      {!loading && (
        <div>
          <Container>
            <Row style={{ justifyContent: "center", marginTop: "2vh" }}>
              <Col xs="12" lg="10">
                <Accordion>
                  {data.map((e) => (
                    <Accordion.Item
                      eventKey={e.yearno}
                      className="AccordianItem"
                      key={e.yearno}
                    >
                      <Accordion.Header className="yemen-text">
                        {e.year}
                      </Accordion.Header>
                      <Accordion.Body
                        style={{ backgroundColor: "rgb(249 249 249)" }}
                      >
                        <Container>
                          <Row style={{}}>
                            <Col xs="6" lg="4" key={e.yearno + "index"}>
                              <Link
                                style={{
                                  fontSize: "3.5vh",
                                  color: "black",
                                  textDecorationThickness: "from-font",
                                  textDecorationColor: "#395fb5",
                                }}
                                to={{
                                  pathname: "/issue/" + e.yearno + "_index",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#395fb5",
                                  }}
                                >
                                  فهرس المقالات
                                </span>
                              </Link>
                            </Col>
                            {data2
                              .filter(function (i) {
                                return i.yearno + "" === e.yearno + "";
                              })
                              .map((e2) => (
                                <Col xs="6" lg="4" key={e2.issue}>
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>{e2.issuedate}</Tooltip>}
                                  >
                                    <Link
                                      style={{
                                        fontSize: "3.5vh",
                                        color: "black",
                                        /*  textDecoration: "none", */
                                      }}
                                      to={{
                                        pathname: "/issue/" + e2.issue,
                                      }}
                                    >
                                      {e2.issue + "" === 180 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            180 و 181
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 182 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            182 و 183
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 219 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            219 و 220
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 221 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            221 و 222
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 223 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            223 و 224
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 235 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            235 و 236
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 239 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            239 و 240
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 241 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            241 و 242
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 243 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            243 و 244
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 245 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            245 و 246
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 262 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            262 و 263
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 269 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            269 و 270
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 283 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            283 و 284
                                          </span>
                                        </div>
                                      ) : e2.issue + "" === 292 + "" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            292 و 293
                                          </span>
                                        </div>
                                      ) : e2.issue === "special" ? (
                                        <div>
                                          عدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            خاص
                                          </span>
                                        </div>
                                      ) : (
                                        <div>
                                          العدد{" "}
                                          <span style={{ color: "#fb3640" }}>
                                            {e2.issue}{" "}
                                          </span>
                                        </div>
                                      )}
                                    </Link>
                                  </OverlayTrigger>
                                </Col>
                              ))}
                          </Row>
                        </Container>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
}

function Home(props) {

  document.title = "جريدة الإيمان"


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {

    setLoading(props.loading);

    if (!loading) {
      setData(gSheet[2].data)

    }
  }, [data, loading, props]);


  return (
    <div>
      {props.loading && ""}
      {!props.loading && (
        <Container>
          <Row style={{ justifyContent: "center", marginTop: "2vh" }}>
            <Col xs="11" lg="10">
              <Intro data={gSheet[2].data} loading={props.loading} />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

function Contact() {

  document.title = "جريدة الإيمان - التواصل"

  const [state, setstate] = useState({
    name: "",
    email: "",
    message: "",
    display: "none",
    hide: null,
  });
  const buttonRef = useRef(null);
  const thankYou = useRef("none");

  useEffect(() => { });

  var handleChange = (e) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const sendMessage = async () => {
    buttonRef.current.disabled = true;

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      "https://d94e0mxye5.execute-api.us-east-2.amazonaws.com/Prod/send?toEmails=" +
      state.email +
      "&message=" +
      state.message +
      "&name=" +
      state.name,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })

      .then((result) => {
        if (result === "Sent") {
          setstate({
            ...state,
            display: null,
            hide: "none",
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div>
      <Form>
        <Container style={{ marginTop: "3vw" }}>
          <Row style={{ justifyContent: "center", display: state.display }}>
            <Col ref={thankYou} xs="11" lg="10" style={{ fontSize: "3vh" }}>
              شكرا لتواصلكم مع جريدة الايمان. سوف نقوم بالرد عليكم عما قريب.
            </Col>
          </Row>
          <Row style={{ justifyContent: "center", display: state.hide }}>
            <Col xs="10" lg="5">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>الاسم</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  type="name"
                  placeholder=""
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>البريد الالكتروني</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="email"
                  type="email"
                  placeholder="name@example.com"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>الرسالة</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  as="textarea"
                  name="message"
                  placeholder="محتوى الرسالة..."
                  rows={7}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center", display: state.hide }}>
            <Col xs="10" lg="5">
              <div style={{ display: "flex" }}>
                <Button
                  variant="primary"
                  type="button"
                  size="lg"
                  style={{
                    marginRight: "auto",
                    backgroundColor: "#fb3640",
                    borderColor: "#ddddd",
                    width: "35%",
                    fontSize: "3.5vh",
                  }}
                  ref={buttonRef}
                  onClick={() => {
                    sendMessage();
                  }}
                >
                  إرسال
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

function Issue(props) {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState("/browse");
  const [prev, setPrev] = useState("/browse");
  const [file, setfile] = useState("");
  const [menuA, setMenueA] = useState("hide");

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreenButton } = fullScreenPluginInstance;

  const scrollModePluginInstance = scrollModePlugin({
    scrollMode: ScrollMode.Vertical,
  });
  // eslint-disable-next-line
  const { SwitchScrollModeButton } = scrollModePluginInstance;

  const selectionModePluginInstance = selectionModePlugin({
    selectionMode: "SelectionMode.Hand",
  });

  const searchPluginInstance = searchPlugin({
    // Highlight "PDF" words initially
    keyword: "PDF",
  });
  // eslint-disable-next-line
  const { ShowSearchPopoverButton } = searchPluginInstance;

  const bookmarkPluginInstance = bookmarkPlugin();

  const { Bookmarks } = bookmarkPluginInstance;

  document.title = "جريدة الإيمان - العدد " + id


  useEffect(() => {
    if (!props.loading) {

      var details = gSheet[0].data;

      for (var i = 0; i < details.length; i++) {
        if (details[i].issue + "" === id) {
          if (details[i].prev) {
            setPrev("/issue/" + details[i].prev);
          } else {
            setPrev("/browse");
          }

          if (details[i].next) {
            setNext("/issue/" + details[i].next);
          } else {
            setNext("/browse");
          }
        }
      }
    }

    async function fetchData() {
      var requestOptions = {
        method: "GET",

        redirect: "follow",
      };

      fetch(
        "https://3d1qba27pl.execute-api.us-east-2.amazonaws.com/default/signURL?issue=" +
        id,
        requestOptions
      )
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          setfile(result);
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
    fetchData();
  }, [id, props.loading]);

  var menuAct = (h) => {
    if (h) {
      setMenueA("hide");
    } else if (menuA === "hide") {
      setMenueA("show");
    } else {
      setMenueA("hide");
    }
  };

  return (
    <div>
      {loading && ""}

      {!loading && (
        <div>
          <div
            id="menue"
            className={"menu " + menuA}
            onClick={() => {
              menuAct();
            }}
          >
            <Bookmarks />
          </div>
          <center>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "3px",
                width: "min-content",
              }}
            >
              {/* <span className="issueNumber">العدد: {id}</span> */}

              <Link className="issueNumber prev" to={{ pathname: prev }}>
                {"<< "}العدد السابق
              </Link>

              <EnterFullScreenButton />
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
              <div className="hideOnMobile" style={{ padding: "0px 2px" }}>
                <ShowSearchPopoverButton className="hideOnMobile" />
              </div>


              <div className="hideOnMobile" style={{ padding: "0px 2px" }}>
                <SwitchScrollModeButton mode={ScrollMode.Vertical} />
              </div>
              <div className="hideOnMobile" style={{ padding: "0px 2px" }}>
                <SwitchScrollModeButton mode={ScrollMode.Horizontal} />
              </div>
              <span
                className="issueNumber TOCButton"
                onClick={() => {
                  menuAct();
                }}
              > فهرس العدد
              </span>
              <Link className="issueNumber next" to={{ pathname: next }}>
                العدد التالي{" >>"}
              </Link>

            </div>
          </center>

          <div
            onContextMenu={(e) => e.preventDefault()}
            className="pagePane"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: "80vh",
              margin: "0.5%",
            }}
          >
            <Container
              fluid
              style={{
                height: "100%",
                justifyContent: "center",
              }}
            >
              <Row
                style={{
                  justifyContent: "center",
                  height: "100%",
                  backgroundColor: "#fbfbfbdd",
                  padding: "0px",
                }}
              >
                <Col
                  style={{ height: "100%", padding: "0px" }}
                  className="d-none d-lg-block"
                  xs="0"
                  lg="3"
                >
                  <Bookmarks />
                </Col>
                <Col
                  xs="0"
                  lg="9"
                  style={{ padding: "0px", height: "100%" }}
                  onClick={() => {
                    menuAct("hide");
                  }}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={file}
                      httpHeaders={{
                        "Access-Control-Allow-Origin": "*",
                      }}
                      defaultScale={SpecialZoomLevel.PageWidth}
                      plugins={[
                        zoomPluginInstance,
                        fullScreenPluginInstance,
                        scrollModePluginInstance,
                        selectionModePluginInstance,
                        searchPluginInstance,
                        bookmarkPluginInstance,
                      ]}
                    />
                  </Worker>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
}

function NoMatch() {
  let location = useLocation();
  document.title = "جريدة الإيمان - 404"


  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
